'use client'

import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import { Nullish } from '../types'

import { EthenaTooltip } from './EthenaTooltip'
import Placeholder from './Placeholder'

export interface StatProps {
  classes?: string
  valueWeight?: 'font-medium' | 'font-semibold' | 'font-bold'
  labelWeight?: 'font-medium' | 'font-semibold' | 'font-bold'
  fontSize?: string
  label: ReactNode
  margin?: string
  stat?: Nullish<string>
  tooltip?: string
  padding?: string
  gap?: 'gap-1' | 'gap-2'
  tooltipWidth?: string
}

export const Stat: FC<StatProps> = ({
  classes = 'bg-[#121216]',
  fontSize = 'text-xs',
  gap = 'gap-2',
  label,
  labelWeight = 'font-medium',
  margin = 'mx-1',
  padding = 'px-2.5 py-1.5',
  stat,
  tooltip,
  tooltipWidth = 'w-[200px]',
  valueWeight = 'font-semibold',
}) => {
  const content = (
    <div
      className={clsx(
        'flex items-center whitespace-nowrap rounded leading-4 text-white',
        margin,
        gap,
        fontSize,
        labelWeight,
        classes,
        padding,
      )}
    >
      <span>{label}</span>
      <span className={clsx(valueWeight, 'text-cblue-400')}>
        <Placeholder isLoading={!stat} classes="w-[42px] h-4">
          {stat}
        </Placeholder>
      </span>
    </div>
  )

  return tooltip ? (
    <EthenaTooltip width={tooltipWidth} padding="p-3" content={tooltip}>
      {content}
    </EthenaTooltip>
  ) : (
    content
  )
}
