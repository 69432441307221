import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/landing-page/components/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InvestorsBox"] */ "/vercel/path0/landing-page/components/InvestorsBox.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/binance-labs.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/bybit.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/deribit.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/dragonfly.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/fidelity.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/franklintempleton.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/okx.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/landing-page/public/investors/wintermute.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@splinetool+react-spline@4.0.0_@splinetool+runtime@1.9.25_next@14.2.11_@babel+core@7.25.2_@op_3uvn6ufqzbffmezly2ypyqj5gy/node_modules/@splinetool/react-spline/dist/react-spline.js");
