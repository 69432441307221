import clsx from 'clsx'
import { motion } from 'framer-motion'

interface AnimatedDividerProps {
  color?: string
  className?: string
}

export const AnimatedDivider = ({ className, color = 'rgba(136,180,245,1)' }: AnimatedDividerProps) => {
  const initialGradient = `linear-gradient(90deg, ${color} 0%, rgba(0,0,0,0.35) 50%)`
  const finalGradient1 = `linear-gradient(90deg, ${color} 0%, rgba(0,0,0,0.35) 50%)`
  const finalGradient2 = `linear-gradient(90deg, rgba(0,0,0,0) 50%, ${color} 100%)`

  return (
    <motion.hr
      initial={{
        background: initialGradient,
      }}
      animate={{
        background: [finalGradient1, finalGradient2],
      }}
      transition={{
        duration: 8,
        ease: 'linear',
        repeat: Infinity,
        repeatDelay: 0,
        repeatType: 'mirror',
        times: [0, 1],
      }}
      className={clsx('bg-[length:200%_100%] bg-[0%_50%] h-0.5 w-full border-none', className)}
    />
  )
}
